import React from 'react';
import ProjectsComponent from '../components/ProjectsComponent';

const Projects = () => {

    return (
        <>
            <ProjectsComponent />
        </>
    )
}

export default Projects;
