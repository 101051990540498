import React from 'react';
import { HomeBg, HomeContainer, HomeContent, Content, Greeting, GreetingDetails, HomeWave } from '../../elements/HomeElements';
import GreetingLottie from '../../common/GreetingLottie';
import CodingLottie from '../../assets/looties/coding.json';
import { greetingDetails } from '../../common/data';
import './Greeting.css';

const GreetingComponent = () => {

    return (
        <HomeContainer id='home'>
            <HomeBg>
                <HomeContent>
                    <Greeting>
                        <h1>Hi there,</h1>
                        <h1>I'm Thet Htet Aung.</h1>
                        <h3 style={{paddingBottom: '40px'}}>Fullstack Developer</h3>
                        <GreetingDetails>
                            {greetingDetails}
                        </GreetingDetails>
                    </Greeting>
                    <Content>
                        <GreetingLottie height={400} width={400} animationPath={CodingLottie} />
                    </Content>
                </HomeContent>
            </HomeBg>

            <HomeWave className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
            </HomeWave>
        </HomeContainer>
    );
}

export default GreetingComponent;
