import React from 'react'
import { DefaultContainer } from '../../elements/Common';
import { ExperienceContainer } from '../../elements/Experience';

const ExperienceComponent = () => {
  return (
    <ExperienceContainer id='experience'>
      <div>Experiences</div>
    </ExperienceContainer>
  )
}

export default ExperienceComponent;