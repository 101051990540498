import styled from "styled-components";

export const HomeContainer = styled.div`
    display:flex;
    margin-top: -80px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    z-index:1;
    :before {
        content: '';
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(0,0,0,0.6) 100%), 
        linear-gradient(180deg, #FFFFFF 0%, transparent 100%), 
        z-index: 2;
    }
    position:relative;
    text-align:center;
    background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
    color:white;
`;

export const HomeBg: any = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const HomeContent = styled.div`
    display: flex;
    flex: 1;
    position:relative;
    width: 100%;
    padding: 0 150px;
    margin-top: -80px;
    justify-content: space-between;
    align-items: center;
`;

export const HomeWave = styled.svg`
    position: absolute;
    bottom: 0;
    width: 100%;
    height:15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:100px;
    max-height:150px;
`;

export const Greeting = styled.div`
    max-width: 500px;
    color: white;
`;

export const GreetingDetails = styled.p`
    color: white;
`;

export const Content = styled.div`
    width: 600px;
    height: 600px;
`;
