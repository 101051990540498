import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ExperienceComponent from '../components/Home/ExperienceComponent';
import GreetingComponent from '../components/Home/GreetingComponent';
import { CustomScrollbar } from '../elements/Common';

const Home = () => {
    return (
        <>
            <GreetingComponent />
            <ExperienceComponent />
        </>
    )
}

export default Home;
