import React, { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Nav, NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu } from '../elements/NavElements';

const NavBar = ({ toggle }: any) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

  return (
    <>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}> Thet Htet Aung</NavLogo>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='home' smooth duration={500} spy exact='true' offset={-80}>
                            Home
                        </NavLinks>
                    </NavItem>
                    
                    <NavItem>
                        <NavLinks to='experience' smooth duration={500} spy exact='true' offset={-80}>
                            Experience
                        </NavLinks>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    </>
  );
}

export default NavBar