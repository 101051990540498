import React from "react";
import Lottie, { Options } from "react-lottie";

const GreetingLottie = ({ animationPath }: any) => {
	const defaultOptions: Options = {
		loop: true,
		autoplay: true,
		animationData: animationPath,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
	};

	return (
		<div onClick={() => null}>
			<Lottie options={defaultOptions} />
		</div>
	);
};

export default GreetingLottie;
