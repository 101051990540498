import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import Projects from './pages/Projects';
import { ThemeProvider } from '@mui/system';
import { theme } from './AppTheme';
import './App.css';
import NavBar from './components/NavBar';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
      setIsOpen(!isOpen);
  }

  return (
      <ThemeProvider theme={theme}>
        <Router>
          <NavBar toggle={toggleNav} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/projects" component={Projects} />
          </Switch>
        </Router>
      </ThemeProvider>
  );
}

export default App;
